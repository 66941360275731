import { navigate, RouteComponentProps } from "@reach/router";
import { Table, toaster } from "evergreen-ui";
import React, { useEffect, useRef, useState } from "react";
import CenteredSpinner from "../../components/General/CenteredSpinner";
import { getAllFurnizori } from "../../utils/api/furnizor";
import { logError } from "../../utils/logger";
import { FurnizorModel } from "../../utils/types/models";

export default function TabelFurnizori(props: RouteComponentProps) {
  const [items, setItems] = useState<FurnizorModel[]>([]);
  // const [searchText, setSearchText] = useState("");

  //   const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let fetchTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const tryGetAllFurnizori = async () => {
      setIsLoading(true);
      try {
        const apiResponse = await getAllFurnizori("");
        if (apiResponse.type) setItems(apiResponse.data);
        else toaster.danger("Eroare preluare date");
      } catch (error) {
        toaster.danger("Eroare conexiune server");
        logError(error);
      } finally {
        setIsLoading(false);
      }
    };
    tryGetAllFurnizori();
    return () => {
      if (fetchTimeout.current) clearTimeout(fetchTimeout.current);
    };
  }, []);

  const changeSearchText = async (value: string) => {
    // setSearchText(value);

    const tryGetAllFurnizori = async () => {
      setIsLoading(true);
      try {
        const apiResponse = await getAllFurnizori(value);
        if (apiResponse.type) setItems(apiResponse.data);
        else toaster.danger("Eroare preluare date");
      } catch (error) {
        toaster.danger("Eroare conexiune server");
        logError(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (fetchTimeout.current) clearTimeout(fetchTimeout.current);
    fetchTimeout.current = setTimeout(tryGetAllFurnizori, 400);
  };

  return (
    <Table>
      <Table.Head>
        <Table.SearchHeaderCell onChange={changeSearchText} placeholder="Caută după denumire/cod/ctr" />
        <Table.TextHeaderCell>Cod client</Table.TextHeaderCell>
        <Table.TextHeaderCell>Nr contract dist</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body height={240}>
        {items.map((furnizor) => (
          <Table.Row key={furnizor.id} isSelectable onSelect={() => navigate(`furnizori/${furnizor.id}`)}>
            <Table.TextCell>{furnizor.denumire}</Table.TextCell>
            <Table.TextCell>{furnizor.codClient}</Table.TextCell>
            <Table.TextCell isNumber>{furnizor.nrContractDist}</Table.TextCell>
          </Table.Row>
        ))}
      </Table.Body>
      {isLoading && <CenteredSpinner />}
    </Table>
  );
}
