import { navigate, RouteComponentProps } from "@reach/router";
import { majorScale, minorScale, Pane, Spinner, Text } from "evergreen-ui";
import React, { useEffect } from "react";
import { logout } from "../../utils/services/keepAuth";
import svgs from "../../utils/svgs";

export default function LogOutPage(props: RouteComponentProps) {
  useEffect(() => {
    logout();
    setTimeout(() => {
      navigate("/");
    }, 1500);
  });
  return (
    <Pane
      // elevation={1}
      width="90vw"
      height="100vh"
      display="flex"
      // alignSelf="center"
      // justifySelf="center"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding={majorScale(8)}
      color="info"
    >
      <Pane width={138} height={162} padding={majorScale(4)}>
        {/* {svgs.logo} */}
        <img style={{ height: "100%" }} src="/logo.png" alt="Logo design-proiect" />
      </Pane>
      <Text is="h3" width={300} marginY={majorScale(2)} color="#000051" fontSize={24} lineHeight={1.2} textAlign="justify">
        Te dezautentificam. In curând vei fi redirecționat către pagina de autentificare.
      </Text>
      <Spinner marginY={majorScale(5)} size={80} />
    </Pane>
  );
}
