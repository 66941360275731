import React, { useState } from "react";
import { Text, majorScale, minorScale, Pane, TextInputField, Button } from "evergreen-ui";
import svgs from "../../utils/svgs";
import { postLogin } from "../../utils/api/login";
import { navigate, RouteComponentProps } from "@reach/router";
import { saveToken } from "../../utils/services/keepAuth";

export default function HomePage(props: RouteComponentProps) {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [formError, setFormError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const changeFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage("");
    setFormError("");
    const { name, value } = event.target;
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: value,
    }));
  };

  const tryUserLogin = async () => {
    setIsLoading(true);
    try {
      // const apiResponse = await postUserLogin(formData);
      const apiResponse = await postLogin(formData);
      if (apiResponse.type) {
        saveToken(apiResponse.data.token, true);
        navigate("/dashboard");
      } else if (apiResponse.reason === 2)
        setErrorMessage(
          "Ați depășit numărul maxim de încercări. Contul dumneavoastră este blocat pentru următoarele două ore. Vă rugăm reîncercați în două ore."
        );
      else setFormError("Email și parolă greșite");
    } catch (error) {
      setErrorMessage("A apărut o eroare internă! Contactați asistența tehnică");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // console.log(formData);
  console.log(isLoading);
  return (
    <Pane
      height="100vh"
      width="100vw"
      background="tint1"
      display="flex"
      alignItems="center"
      justifyContent="center"
      // backgroundImage="url('/images/background.jpg')"
      // backgroundSize="auto"
      // backgroundPosition="center"
      // backgroundRepeat="no-repeat"
    >
      <Pane
        elevation={2}
        // height={600}
        width={400}
        background="tint2"
        display="flex"
        alignItems="center"
        flexDirection="column"
        padding={majorScale(8)}
        color="info"
      >
        <Pane width={138} height={162} marginY={minorScale(4)}>
          {/* {svgs.logo} */}
          <img style={{ height: "100%" }} src="/logo.png" alt="Logo design-proiect" />
        </Pane>
        <Text is="h3" marginY={majorScale(2)} color="#000051" fontSize={32} fontWeight="bold">
          Log in
        </Text>
        <TextInputField
          type="email"
          name="email"
          label="E-mail"
          placeholder="Enter your email address"
          width={300}
          marginY={majorScale(2)}
          value={formData.email}
          onChange={changeFormData}
          isInvalid={!!formError}
          validationMessage={formError || null}
        />
        <TextInputField
          type="password"
          name="password"
          label="Password"
          placeholder="Enter your password"
          width={300}
          value={formData.password}
          onChange={changeFormData}
          isInvalid={!!formError}
          validationMessage={formError || null}
        />
        <Button appearance="primary" width={300} height={48} justifyContent="center" padding={0} onClick={tryUserLogin} isLoading={isLoading}>
          Log in
        </Button>
        {errorMessage && (
          <Text marginY={majorScale(4)} color="red" fontSize={16}>
            {errorMessage}
          </Text>
        )}
      </Pane>
    </Pane>
  );
}
