import { checkStatus, apiBase, ApiResponse } from "./utils";

interface ApiLoginResponse extends ApiResponse<{ token: string }> {
  reason: number;
}

export function postLogin(data: { email: string; password: string }): Promise<ApiLoginResponse> {
  const requestOptions = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${apiBase}/adminLogin`, requestOptions).then(checkStatus) as Promise<ApiLoginResponse>;
}
