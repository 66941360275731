import { FurnizorFormData, FurnizorModel } from "../types/models";
import { adminApiBase, ApiResponse, customDelete, customGet, customPost, customPut } from "./utils";

const furnizoriApiBase = `${adminApiBase}/furnizori`;
export function getAllFurnizori(searchText: string) {
  return customGet(`${furnizoriApiBase}/all?searchText=${searchText}`) as Promise<ApiResponse<FurnizorModel[]>>;
}

export function getFurnizorById(ifFurnizor: string): Promise<ApiResponse<FurnizorModel>> {
  return customGet(`${furnizoriApiBase}/${ifFurnizor}`) as Promise<ApiResponse<FurnizorModel>>;
}

export function putFurnizor(idFurnizor: number, data: FurnizorFormData): Promise<ApiResponse<null>> {
  return customPut(`${furnizoriApiBase}/${idFurnizor}`, data) as Promise<ApiResponse<null>>;
}

export function postFurnizor(data: FurnizorFormData): Promise<ApiResponse<null>> {
  return customPost(`${furnizoriApiBase}`, data) as Promise<ApiResponse<null>>;
}

export function deleteFurnizor(idFurnizor: number): Promise<ApiResponse<null>> {
  return customDelete(`${furnizoriApiBase}/${idFurnizor}`) as Promise<ApiResponse<null>>;
}
