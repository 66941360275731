import { RouteComponentProps } from "@reach/router";
import { Button, majorScale, Pane, Text, toaster } from "evergreen-ui";
import React, { useState } from "react";
import ExtraTextInputField from "../../components/General/ExtraTextInputField";
import { postFurnizor } from "../../utils/api/furnizor";
import { ALFA_NUMERIC_ERROR, ALFA_NUMERIC_REGEX } from "../../utils/regex";
import { FurnizorFormData } from "../../utils/types/models";

const checkFormDisabled = (formData: FurnizorFormData): boolean => {
  return !(ALFA_NUMERIC_REGEX(formData.denumire) && ALFA_NUMERIC_REGEX(formData.codClient) && ALFA_NUMERIC_REGEX(formData.nrContractDist));
};

export default function AddFurnizor(props: RouteComponentProps) {
  const [formData, setFormData] = useState({
    denumire: "",
    codClient: "",
    nrContractDist: "",
  });

  // const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const changeFormData = (name: string, value: string | number) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: value,
    }));
  };

  const tryAddFurnizor = async () => {
    setIsLoading(true);
    try {
      const apiResponse = await postFurnizor(formData);
      if (apiResponse.type) {
        toaster.success("Furnizorul a fost adăugat cu succes!");
      } else toaster.danger("Eroare la salvarea datelor!");
    } catch (error) {
      toaster.danger("Eroare la salvarea datelor!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // console.log(formData);
  console.log(isLoading);
  return (
    <Pane
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      // backgroundImage="url('/images/background.jpg')"
      // backgroundSize="auto"
      // backgroundPosition="center"
      // backgroundRepeat="no-repeat"
    >
      <Pane
        elevation={2}
        // height={600}
        width={400}
        display="flex"
        alignItems="center"
        flexDirection="column"
        padding={majorScale(2)}
        color="info"
      >
        <Text is="p" marginTop={majorScale(1)} color="#000051" fontSize={24} fontWeight="bold">
          Adaugă furnizor nou
        </Text>
        <ExtraTextInputField
          name="denumire"
          label="Denumire furnizor"
          placeholder="Denumire furnizor nou"
          marginTop={majorScale(2)}
          value={formData.denumire}
          onChange={changeFormData}
          errorTest={ALFA_NUMERIC_REGEX}
          errorMessage={ALFA_NUMERIC_ERROR}
        />
        <ExtraTextInputField
          name="codClient"
          label="Cod client furnizor"
          placeholder="Cod client furnizor nou"
          value={formData.codClient}
          onChange={changeFormData}
          errorTest={ALFA_NUMERIC_REGEX}
          errorMessage={ALFA_NUMERIC_ERROR}
        />
        <ExtraTextInputField
          name="nrContractDist"
          label="Nr ctr dist furnizor"
          placeholder="Nr ctr dist furnizor nou"
          marginTop={majorScale(2)}
          value={formData.nrContractDist}
          onChange={changeFormData}
          errorTest={ALFA_NUMERIC_REGEX}
          errorMessage={ALFA_NUMERIC_ERROR}
        />
        <Button
          appearance="primary"
          width={300}
          height={48}
          justifyContent="center"
          padding={0}
          disabled={checkFormDisabled(formData)}
          onClick={tryAddFurnizor}
          isLoading={isLoading}
        >
          Adaugă
        </Button>
        {/* {errorMessage && (
          <Text marginY={majorScale(4)} color="red" fontSize={16}>
            {errorMessage}
          </Text>
        )} */}
      </Pane>
    </Pane>
  );
}
