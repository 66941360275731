import { IconButton, majorScale, Table, TrashIcon } from "evergreen-ui";
import React from "react";
import CenteredSpinner from "../../components/General/CenteredSpinner";
import { dateToDesiredFormat } from "../../utils/formatters/date";
import { AsociereClc } from "../../utils/types/models";

type PropTypes = {
  items: AsociereClc[];
  isLoading: boolean;
  showDeleteAsociereClcDialog: (idAsociere: number) => void;
};

export default function TabelAsocieriClc({ items, isLoading, showDeleteAsociereClcDialog }: PropTypes) {
  return (
    <Table width={600} marginTop={majorScale(4)}>
      <Table.Head>
        <Table.TextHeaderCell flexBasis={250} flexShrink={0} flexGrow={0}>
          Clc
        </Table.TextHeaderCell>
        <Table.TextHeaderCell flexBasis={250} flexShrink={0} flexGrow={0}>
          Perioadă acces
        </Table.TextHeaderCell>
        <Table.TextHeaderCell>Acțiuni</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body height={240}>
        {items.map((item) => (
          <Table.Row key={item.id}>
            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0}>
              {item.codLocConsum}
            </Table.TextCell>
            <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0}>
              {dateToDesiredFormat(new Date(item.createdAt)) + "-" + dateToDesiredFormat(new Date(item.accesValid))}
            </Table.TextCell>
            <Table.TextCell>
              <IconButton icon={TrashIcon} intent="danger" marginRight={majorScale(2)} onClick={() => showDeleteAsociereClcDialog(item.id)} />
            </Table.TextCell>
          </Table.Row>
        ))}
      </Table.Body>
      {isLoading && <CenteredSpinner />}
    </Table>
  );
}
