import { navigate, RouteComponentProps } from "@reach/router";
import { LogOutIcon, majorScale, Menu, minorScale, Pane, PeopleIcon } from "evergreen-ui";
import React, { ReactNode, useEffect } from "react";
import { isLoggedIn } from "../../utils/services/keepAuth";
import svgs from "../../utils/svgs";

interface DashLayoutProps extends RouteComponentProps {
  children: ReactNode;
}

export default function DashLayout(props: DashLayoutProps) {
  useEffect(() => {
    if (!isLoggedIn()) navigate("/");
  }, []);
  // console.log("RENDERING DASH LAYOUT");
  return (
    <Pane height="100vh" width="100vw" display="flex">
      <Pane display="flex" flex={1} height="100vh" flexDirection="column" elevation={1} background="tint2">
        <Pane flex={1} alignSelf="stretch">
          {/* <Pane padding={majorScale(4)}> */}
          <Pane width={138} height={162} padding={majorScale(4)}>
            {/* {svgs.logo} */}
            <img style={{ height: "100%" }} src="/logo.png" alt="Logo design-proiect" />
          </Pane>
        </Pane>
        <Pane flex={9} alignSelf="stretch">
          <Menu>
            <Menu.Group>
              <Menu.Item backgroundColor="none" icon={PeopleIcon} onSelect={() => navigate("/dashboard/furnizori")}>
                Furnizori
              </Menu.Item>
            </Menu.Group>
            <Menu.Divider />
            <Menu.Group>
              <Menu.Item icon={LogOutIcon} backgroundColor="none" intent="danger" onSelect={() => navigate("/dashboard/logout")}>
                Delogare
              </Menu.Item>
            </Menu.Group>
          </Menu>
        </Pane>
      </Pane>
      <Pane display="flex" flex={9} height="100vh" justifyContent="stretch" backgroundColor="white">
        {props.children}
      </Pane>
    </Pane>
  );
}
