import { PolymorphicBoxProps, TextInputField } from "evergreen-ui";
import React, { useEffect, useState } from "react";

type PropTypes = {
  type?: string;
  name: string;
  label: string;
  placeholder: string;
  isRequired?: boolean;
  width?: number;
  value: string;
  onChange: (name: string, value: string | number) => void;
  errorTest?: (value: string) => boolean;
  showErrorWithoutTouched?: boolean;
  errorMessage: string;
  requiredMessage?: string;
};

export default function ExtraTextInputField({
  type = "text",
  name,
  label,
  placeholder,
  isRequired = true,
  width = 300,
  value,
  onChange,
  errorTest,
  showErrorWithoutTouched = false,
  errorMessage,
  requiredMessage = "Câmp obligatoriu",
  ...rest
}: PolymorphicBoxProps<"div", PropTypes>) {
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(isRequired ? requiredMessage : null);
  //   const [formType, setFormType] = useState(type);

  useEffect(() => {
    if (isRequired && (!value || value === "")) setError(requiredMessage);
    else if (errorTest)
      if (errorTest(value)) setError(null);
      else setError(errorMessage);
    else setError(null);
  }, [isRequired, value, errorMessage, requiredMessage, errorTest]);

  //   const makeFormVisible = () => {
  //     setFormType("text");
  //   };
  //   const makeFormInvisible = () => {
  //     setFormType("password");
  //   };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = event.target;
    // if (isRequired && value === "") setError(requiredMessage)
    // else if (errorTest)
    //   if (errorTest(value)) setError("")
    //   else setError(errorMessage)
    // else setError("")
    onChange(name, value);
  };

  const handleBlur = (): void => {
    setTouched(true);
    if (isRequired && (!value || value === "")) setError(requiredMessage);
    else if (errorTest)
      if (errorTest(value)) setError(null);
      else setError(errorMessage);
    else setError(null);
  };

  // console.log(error)

  //   let inputStyles;
  //   if ((touched || showErrorWithoutTouched || (value && value.length > 0)) && error) inputStyles = { ...style, ...styles.errorStyles };
  //   else inputStyles = style;

  return (
    <TextInputField
      key={name}
      type={type}
      name={name}
      label={label}
      placeholder={placeholder}
      isRequired={isRequired}
      width={width}
      value={value}
      onChange={handleInputChange}
      isInvalid={!!error}
      validationMessage={error}
      onBlur={!touched ? handleBlur : undefined}
      //   {...rest}
    />
  );
}
