import { RouteComponentProps } from "@reach/router";
import { majorScale, Pane, Tab, Tablist } from "evergreen-ui";
import React, { useState } from "react";
import AddFurnizor from "./addFurnizor";
import TabelFurnizori from "./TabelFurnizori";

const tabs = ["Tabel furnizori", "Adaugă furnizor"];

export default function FurnizoriPage(props: RouteComponentProps) {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Pane display="flex" flex={1} height="100vh" flexDirection="column">
      <Tablist
        display="flex"
        width="100%"
        height={64}
        paddingTop={majorScale(3)}
        justifyContent="flex-start"
        alignItems="center"
        background="tint2"
        borderBottom="1px solid #edeff5"
      >
        {tabs.map((tab, index) => (
          <Tab key={tab} id={tab} onSelect={() => setTabIndex(index)} isSelected={index === tabIndex} aria-controls={`panel-${tab}`}>
            {tab}
          </Tab>
        ))}
      </Tablist>
      <Pane padding={16} flex="1" width="90vw">
        {tabIndex === 0 && <TabelFurnizori />}
        {tabIndex === 1 && <AddFurnizor />}
        {/* {tabs.map((tab, index) => (
          <Pane
            key={tab}
            id={`panel-${tab}`}
            role="tabpanel"
            aria-labelledby={tab}
            aria-hidden={index !== tabIndex}
            display={index === tabIndex ? "block" : "none"}
          >
            <Paragraph>Panel {tab}</Paragraph>
          </Pane>
        ))} */}
      </Pane>
    </Pane>
  );
}
