let memoryToken: string;

export function saveToken(token: string, keepAuth: boolean) {
  if (token && keepAuth) {
    memoryToken = token;
    localStorage.setItem("token", token);
  } else if (token) memoryToken = token;
}

export function getToken() {
  if (memoryToken) return memoryToken;
  else return localStorage.getItem("token") || "";
}

export function isLoggedIn() {
  // console.log(memoryToken);
  return memoryToken || localStorage.getItem("token");
}

export function logout() {
  memoryToken = "";
  localStorage.removeItem("token");
}
