import { AsociereClc } from "../types/models";
import { adminApiBase, ApiResponse, customDelete, customPost } from "./utils";

const asocieriClcApiBase = `${adminApiBase}/asocieriClc`;
export function postAsociereClcTemporar(data: { clc: string; dataValid: Date; idFurnizor: number }) {
  return customPost(`${asocieriClcApiBase}/`, data) as Promise<ApiResponse<AsociereClc>>;
}

export function deleteAsociereClc(idAsociere: number) {
  return customDelete(`${asocieriClcApiBase}/${idAsociere}`) as Promise<ApiResponse<null>>;
}
