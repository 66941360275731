import { Router } from "@reach/router";
import React from "react";
import "./App.css";
import DashLayout from "./components/Layout/Dash";
import FurnizoriPage from "./pages/Furnizori";
import EditFurnizor from "./pages/Furnizori/EditFurnizor";
import HomePage from "./pages/Home";
import LogOutPage from "./pages/Logout";

function App() {
  return (
    <Router>
      <HomePage path="/" />
      <DashLayout path="dashboard">
        <FurnizoriPage path="furnizori" />
        <EditFurnizor path="furnizori/:idFurnizor" />
        <LogOutPage path="logout" />
      </DashLayout>
    </Router>
  );
}

export default App;
