import { navigate, RouteComponentProps } from "@reach/router";
import { Autocomplete, Button, Dialog, EditIcon, majorScale, Pane, Text, TextInput, toaster, TrashIcon } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import ExtraTextInputField from "../../components/General/ExtraTextInputField";
import { deleteAsociereClc, postAsociereClcTemporar } from "../../utils/api/asocieriClc";
import { getAllClc } from "../../utils/api/consumuri";
import { deleteFurnizor, getFurnizorById, putFurnizor } from "../../utils/api/furnizor";
import { logError } from "../../utils/logger";
import { ALFA_NUMERIC_ERROR, ALFA_NUMERIC_REGEX } from "../../utils/regex";
import { AsociereClc, FurnizorFormData } from "../../utils/types/models";
import TabelAsocieriClc from "./TabelAsocieriClc";

const checkFormDisabled = (formData: FurnizorFormData): boolean => {
  return !(ALFA_NUMERIC_REGEX(formData.denumire) && ALFA_NUMERIC_REGEX(formData.codClient) && ALFA_NUMERIC_REGEX(formData.nrContractDist));
};

interface PropTypes extends RouteComponentProps {
  idFurnizor?: string;
}

export default function EditFurnizor(props: PropTypes) {
  const [formData, setFormData] = useState({
    id: -1,
    denumire: "",
    codClient: "",
    nrContractDist: "",
  });

  const [asocieriClc, setAsocieriClc] = useState<AsociereClc[]>([]);

  const [clcSelectat, setClcSelectat] = useState(null);
  const [clcuri, setClcuri] = useState<string[]>([]);
  // const [errorMessage, setErrorMessage] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [idAsociereClcDeSters, setIdAsociereClcDeSters] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const tryGetFurnizorData = async (idFurnizor: string) => {
      setIsLoading(true);
      try {
        const apiResponse = await getFurnizorById(idFurnizor);
        if (apiResponse.type) {
          setFormData(apiResponse.data);
          setAsocieriClc(apiResponse.data.accesClcuriTemporar);
        } else toaster.danger("Eroare preluare date");
      } catch (error) {
        toaster.danger("Eroare conexiune server");
        logError(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (props.idFurnizor) tryGetFurnizorData(props.idFurnizor);
  }, [props.idFurnizor]);

  const changeFormData = (name: string, value: string | number) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: value,
    }));
  };

  const tryEditFurnizor = async () => {
    setIsLoading(true);
    try {
      const apiResponse = await putFurnizor(formData.id, formData);
      if (apiResponse.type) {
        toaster.success("Furnizorul a fost salvat cu succes!");
      } else toaster.danger("Eroare la salvarea datelor!");
    } catch (error) {
      toaster.danger("Eroare la salvarea datelor!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const tryDeleteFurnizor = async () => {
    setIsLoading(true);
    try {
      const apiResponse = await deleteFurnizor(formData.id);
      if (apiResponse.type) {
        toaster.success("Furnizorul a fost șters cu succes!");
        setTimeout(() => {
          navigate("/dashboard/furnizori");
        }, 2500);
      } else toaster.danger("Eroare la salvarea datelor!");
    } catch (error) {
      toaster.danger("Eroare la salvarea datelor!");
      console.error(error);
    } finally {
      setIsLoading(false);
      setShowDeleteDialog(false);
    }
  };

  const changeSearchClcValue = async (inputValue: string) => {
    try {
      const apiResponse = await getAllClc(inputValue);
      // console.log(apiResponse);
      if (apiResponse.type) setClcuri(apiResponse.data);
    } catch (error) {
      logError(error);
    }
  };

  const tryAsociereClc = async () => {
    if (clcSelectat)
      try {
        const datePlus5Days = new Date();
        datePlus5Days.setDate(datePlus5Days.getDate() + 7);
        const apiResponse = await postAsociereClcTemporar({ clc: clcSelectat, dataValid: datePlus5Days, idFurnizor: formData.id });
        // console.log(apiResponse);
        if (apiResponse.type) {
          setAsocieriClc((oldItems) => [apiResponse.data].concat(oldItems));
          setClcSelectat(null);
        }
      } catch (error) {
        logError(error);
      }
  };

  const showDeleteAsociereClcDialog = (idAsociere: number) => setIdAsociereClcDeSters(idAsociere);

  const tryDeleteAsociereClc = async () => {
    setIsLoading(true);
    try {
      const apiResponse = await deleteAsociereClc(idAsociereClcDeSters);
      if (apiResponse.type) {
        toaster.success("Asocierea clc-furnizor a fost ștearsă cu succes!");
        setAsocieriClc((oldItems) => oldItems.filter((entry) => entry.id !== idAsociereClcDeSters));
        setIdAsociereClcDeSters(0);
      } else toaster.danger("Eroare la salvarea datelor!");
    } catch (error) {
      toaster.danger("Eroare la salvarea datelor!");
      console.error(error);
    } finally {
      setIsLoading(false);
      setShowDeleteDialog(false);
    }
  };
  // console.log(formData);
  console.log(clcSelectat);
  return (
    <Pane
      height="100vh"
      width="90vw"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
      // backgroundImage="url('/images/background.jpg')"
      // backgroundSize="auto"
      // backgroundPosition="center"
      // backgroundRepeat="no-repeat"
    >
      <Pane
        // elevation={1}
        // height={600}
        marginTop={majorScale(4)}
        width={400}
        display="flex"
        alignItems="center"
        flexDirection="column"
        padding={majorScale(2)}
        color="info"
      >
        <Text is="p" marginTop={majorScale(1)} color="#000051" fontSize={24} fontWeight="bold">
          Modifică furnizor
        </Text>
        <ExtraTextInputField
          name="denumire"
          label="Denumire furnizor"
          placeholder="Denumire furnizor nou"
          marginTop={majorScale(2)}
          value={formData.denumire}
          onChange={changeFormData}
          errorTest={ALFA_NUMERIC_REGEX}
          errorMessage={ALFA_NUMERIC_ERROR}
        />
        <ExtraTextInputField
          name="codClient"
          label="Cod client furnizor"
          placeholder="Cod client furnizor nou"
          value={formData.codClient}
          onChange={changeFormData}
          errorTest={ALFA_NUMERIC_REGEX}
          errorMessage={ALFA_NUMERIC_ERROR}
        />
        <ExtraTextInputField
          name="nrContractDist"
          label="Nr ctr dist furnizor"
          placeholder="Nr ctr dist furnizor nou"
          marginTop={majorScale(2)}
          value={formData.nrContractDist}
          onChange={changeFormData}
          errorTest={ALFA_NUMERIC_REGEX}
          errorMessage={ALFA_NUMERIC_ERROR}
        />
        <Pane flexDirection="column">
          <Button
            appearance="primary"
            width={200}
            height={32}
            fontSize={24}
            justifyContent="center"
            padding={0}
            disabled={checkFormDisabled(formData)}
            onClick={tryEditFurnizor}
            isLoading={isLoading}
            iconAfter={EditIcon}
          >
            Modifică
          </Button>
          <Button
            appearance="minimal"
            marginLeft={16}
            intent="danger"
            iconAfter={TrashIcon}
            onClick={() => setShowDeleteDialog(true)}
            isLoading={isLoading}
          >
            Delete
          </Button>
        </Pane>

        {/* {errorMessage && (
          <Text marginY={majorScale(4)} color="red" fontSize={16}>
            {errorMessage}
          </Text>
        )} */}
      </Pane>
      <Pane
        // elevation={1}
        // height={600}
        marginTop={majorScale(4)}
        width={600}
        display="flex"
        alignItems="center"
        flexDirection="column"
        padding={majorScale(2)}
        color="info"
      >
        <Text is="p" marginTop={majorScale(1)} color="#000051" fontSize={24} fontWeight="bold">
          Asociază clc temporar
        </Text>
        {/* <Pane width={600} flexDirection="row"> */}
        <Autocomplete
          title="Caută clc"
          onChange={(changedItem) => setClcSelectat(changedItem.codLocConsum)}
          items={clcuri}
          isFilterDisabled
          onInputValueChange={changeSearchClcValue}
          itemToString={(item) => (item ? item.codLocConsum : "")}
        >
          {(props) => {
            const { getInputProps, getRef, inputValue } = props;

            return (
              <Pane ref={getRef} display="inline-block" marginLeft={50} marginTop={majorScale(3)}>
                <TextInput placeholder="Caută clc" {...getInputProps()} ref={getRef} value={inputValue} />
                <Button
                  appearance="primary"
                  width={160}
                  height={32}
                  marginLeft={majorScale(2)}
                  fontSize={24}
                  justifyContent="center"
                  padding={0}
                  disabled={!clcSelectat}
                  onClick={tryAsociereClc}
                  // isLoading={isLoading}
                  iconAfter={EditIcon}
                >
                  Asociază temporar
                </Button>
              </Pane>
            );
          }}
        </Autocomplete>
        <TabelAsocieriClc items={asocieriClc} isLoading={isLoading} showDeleteAsociereClcDialog={showDeleteAsociereClcDialog} />
      </Pane>
      <Dialog
        isShown={showDeleteDialog}
        title="Ștergere furnizor"
        intent="danger"
        onCloseComplete={() => setShowDeleteDialog(false)}
        confirmLabel="Delete"
        isConfirmLoading={isLoading}
        onConfirm={tryDeleteFurnizor}
      >
        Sunteți sigur că doriți ștergerea acestui furnizor?
      </Dialog>

      <Dialog
        isShown={idAsociereClcDeSters > 0}
        title="Ștergere asociere clc - furnizor"
        intent="danger"
        onCloseComplete={() => setIdAsociereClcDeSters(0)}
        confirmLabel="Delete"
        isConfirmLoading={isLoading}
        onConfirm={tryDeleteAsociereClc}
      >
        Sunteți sigur că doriți ștergerea acestui clc din lista de asocieri?
      </Dialog>
    </Pane>
  );
}
